/*
 * Created with React Create App
 * https://github.com/facebook/create-react-app
 */

import React from "react";
import { ThemeProvider } from "styled-components";

import { themes } from "./Themes";

import Router from "./Router";

function App() {
  return (
    <ThemeProvider theme={themes}>
      <Router />
    </ThemeProvider>
  );
}

export default App;

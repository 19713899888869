const grey = {
  default: "#323232",
  dark: "#212121",
  borders: "#999999",
};

const breakpoints = {
  sm: 884,
  md: 1200,
};

export const themes = {
  colors: {
    white: "#ffffff",
    primary: "#f9e547",
    secondary: {
      main: "#0049db",
      dark: "#0340c0",
    },
    cards: {
      main: "#e5efe8",
      dark: "#3b844d",
    },
    grey,
  },
  breakpoints,
};

import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { lazy, Suspense } from "react";

const Listings = lazy(() => import("./pages/Listings"));

const HOME = "/";

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={HOME} component={Listings} />
          <Redirect to={HOME} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
